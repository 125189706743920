import _ from "lodash";
import Entity from "app/core/entities/Entity";

class GeoPoint extends Entity {
    /**
     * @public
     * @method getLatitude
     * @returns {number}
     */
    getLatitude() {
        return this.entity.latitude || 0;
    }

    /**
     * @public
     * @method setLatitude
     * @param value {number}
     * @returns {GeoPoint}
     */
    setLatitude(value) {
        if (_.isNumber(value)) {
            this.entity.latitude = value;
        }

        return this;
    }

    /**
     * @public
     * @method getLongitude
     * @returns {number}
     */
    getLongitude() {
        return this.entity.longitude || 0;
    }

    /**
     * @public
     * @method setLongitude
     * @param value {number}
     * @returns {GeoPoint}
     */
    setLongitude(value) {
        if (_.isNumber(value)) {
            this.entity.longitude = value;
        }

        return this;
    }
}

export default GeoPoint;
