/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";

import _ from "lodash";

class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        /**
         * @property placeholder
         * @type {string}
         */
        this.placeholder = props.placeholder;

        /**
         * @property item
         * @type {Object}
         */
        this.item = null;

        this.state = {
            queryString: props.queryString,
            items: []
        };

        this.changeQuery = this.changeQuery.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this._deleteSelectedItem = this._deleteSelectedItem.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.getItemsByQuery = _.debounce(this.getItemsByQuery.bind(this), 250);
        this.getItemName = this.getItemName.bind(this);
        this.setItems = this.setItems.bind(this);
    }

    /**
     * @protected
     * @method componentDidUpdate
     * @param prevProps {Object}
     * @returns {void}
     */
    componentDidUpdate(prevProps) {
        if (prevProps.queryString !== this.props.queryString) {
            this.setState(() => {
                return {
                    queryString: this.props.queryString,
                    items: []
                };
            });
        }
    }

    /**
     * @method _isAvailableClearButton
     * @return {boolean}
     * @private
     */
    _isAvailableClearButton() {
        return Boolean(this.props.hasClearButton && (this.item || this.state.queryString));
    }

    /**
     * @method getItemName
     * @param item {Object}
     * @returns {string}
     */
    getItemName(item) {
        return (item && item.getName()) || "";
    }

    /**
     * @method getItemsByQuery
     * @param opts {Object}
     * @returns {Autocomplete}
     */
    getItemsByQuery(opts) {
        let itemName = this.getItemName(this.item);

        if (!itemName.length || (itemName !== opts.value)) {
            this.props.getItemsByQuery(opts.value, this.setItems);
        }

        return this;
    }

    /**
     * @method setItems
     * @param items {Array}
     * @return {Autocomplete}
     */
    setItems(items) {
        this.setState(function () {
            return {items};
        });

        return this;
    }

    /**
     * @method _setQuery
     * @param value {string}
     * @returns {Autocomplete}
     */
    _setQuery(value) {
        this.setState(function () {
            return {
                queryString: value
            };
        });

        return this;
    }

    /**
     * @method changeQuery
     * @param event {Object}
     * @param opts {Object}
     * @returns {Autocomplete}
     */
    changeQuery(event, opts) {
        this._setQuery(opts.newValue);

        return this;
    }

    /**
     * @method selectItem
     * @param event {Object}
     * @param opts {Object}
     * @returns {boolean}
     */
    selectItem(event, opts) {
        this.item = opts.suggestion;

        this.props.selectItem(this.item);

        this.setItems([]);

        event.preventDefault();

        return false;
    }

    /**
     * @method _deleteSelectedItem
     * @return {Autocomplete}
     * @private
     */
    _deleteSelectedItem() {
        this.item = null;

        this.props.selectItem(this.item);

        this.setItems([])._setQuery("");

        return this;
    }

    /**
     * @method renderItem
     * @param item {Object}
     * @returns {string}
     */
    renderItem(item) {
        return this.props.renderItem(item);
    }

    /**
     * @method render
     * @returns {string}_deleteSelectedItem
     */
    render() {
        let inputProps = {
            autoFocus: this.props.autoFocus,
            name: this.props.name,
            placeholder: this.placeholder,
            onBlur: this.props.onBlur,
            value: this.state.queryString,
            disabled: this.props.disabled,
            onChange: this.changeQuery,
            className: "form-control"
        }

        return (
            <div className="autocomplete">
                <div className="autocomplete__body">
                    <Autosuggest
                        suggestions={this.state.items}
                        onSuggestionsFetchRequested={this.getItemsByQuery}
                        // onSuggestionsClearRequested={() => {}}
                        onSuggestionSelected={this.selectItem}
                        getSuggestionValue={this.getItemName}
                        renderSuggestion={this.renderItem}
                        alwaysRenderSuggestions={true}
                        focusInputOnSuggestionClick={false}
                        inputProps={inputProps}
                    />

                    {this._isAvailableClearButton() && (
                        <span
                            className="icon icon-close autocomplete__clear"
                            onClick={this._deleteSelectedItem}
                        />
                    )}
                </div>
            </div>
        );
    }
}

Autocomplete.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    queryString: PropTypes.string,
    autoFocus: PropTypes.bool,
    hasClearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    getItemsByQuery: PropTypes.func.isRequired,
    selectItem: PropTypes.func,
    onBlur: PropTypes.func,
    renderItem: PropTypes.func
};

Autocomplete.defaultProps = {
    name: "выбрать",
    placeholder: "выбрать",
    queryString: "",
    autoFocus: true,
    hasClearButton: false,
    disabled: false,
    selectItem: () => {},
    onBlur: () => {},
    renderItem: (item) => {
        return (
            <a href="#">{item.getName()}</a>
        );
    }
};

export default Autocomplete;
