class DrugstoreDetector {
    constructor() {
        /**
         * @property _drugstoreNames
         * @type {{a24: string}}
         * @private
         */
        this._drugstoreNames = {
            a24: "аптека24"
        };
    }

    /**
     * @method isA24
     * @param name {string}
     * @returns {boolean}
     */
    isA24(name) {
        return String(name).toLowerCase() === this._drugstoreNames.a24;
    }
}

export default DrugstoreDetector;
