import _ from "lodash";
import GeoPoint from "app/core/entities/map/GeoPoint";

class Marker extends GeoPoint {
    /**
     * @public
     * @method getName
     * @returns {string}
     */
    getName() {
        return this.entity.name || "";
    }

    /**
     * @public
     * @method setName
     * @param value {string}
     * @returns {Marker}
     */
    setName(value) {
        if (_.isString(value)) {
            this.entity.name = value;
        }

        return this;
    }

    /**
     * @public
     * @method getAddressAsText
     * @returns {string}
     */
    getAddressAsText() {
        return this.entity.address || "";
    }

    /**
     * @public
     * @method setAddressAsText
     * @param value {string}
     * @returns {Marker}
     */
    setAddressAsText(value) {
        if (_.isString(value)) {
            this.entity.address = value;
        }

        return this;
    }
}

export default Marker;
