import _ from "lodash";

import Marker from "app/core/entities/map/marker/Marker";

class DeliveryPointMarker extends Marker {
    /**
     * @public
     * @method hasSelfDelivery
     * @returns {boolean}
     */
    hasSelfDelivery() {
        return Boolean(this.entity.selfService);
    }

    /**
     * @public
     * @method setAddressAsText
     * @param value {string}
     * @returns {Marker}
     */
    setSelfDelivery(value) {
        this.entity.selfService = Boolean(value);

        return this;
    }

    /**
     * @public
     * @method getPhone
     * @returns {string}
     */
    getPhone() {
        return this.entity.phone || "";
    }

    /**
     * @public
     * @method setPhone
     * @param value {string}
     * @returns {DeliveryPointMarker}
     */
    setPhone(value) {
        if (_.isString(value)) {
            this.entity.phone = value;
        }

        return this;
    }

    /**
     * @public
     * @method getWorkTime
     * @returns {string}
     */
    getWorkTime() {
        return this.entity.workTime || "";
    }

    /**
     * @public
     * @method setWorkTime
     * @param value {string}
     * @returns {string}
     */
    setWorkTime(value) {
        if (_.isString(value)) {
            this.entity.workTime = value;
        }

        return this;
    }

    /**
     * @public
     * @method getUrl
     * @returns {string}
     */
    getUrl() {
        return this.entity.url || "";
    }

    /**
     * @public
     * @method setIconUrl
     * @param url {string}
     * @return {DeliveryPointMarker}
     */
    setIconUrl(url) {
        if (_.isString(url)) {
            this.entity.iconUrl = url;
        }

        return this;
    }

    /**
     * @public
     * @method getIconUrl
     * @return {string}
     */
    getIconUrl() {
        return this.entity.iconUrl || "";
    }
}

export default DeliveryPointMarker;
